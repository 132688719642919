import { useState } from 'react'

const useForm = ({ initialValues, initialFormContext }) => {
    const [formError, setFormError] = useState('')
    const [formData, setFormData] = useState(initialValues || {})

    // initialize context to be comprehensive
    const keys = Object.keys(initialValues)
    keys.forEach((key) => {
        if (initialFormContext[key]) {
            return
        } else {
            initialFormContext[key] = {}
        }
    })
    const [formContext, setFormContext] = useState(initialFormContext || {})

    const handleChange = (key, val, sanitizeFn) => {
        const value = sanitizeFn ? sanitizeFn(val) : val
        const { getError, isDirty, required } = formContext[key]

        setFormData((prevState) => {
            return {
                ...prevState,
                [key]: val,
            }
        })
        setFormContext((prevState) => {
            return {
                ...prevState,
                [key]: {
                    getError,
                    error: getError && getError(value, formData),
                    isDirty,
                    required,
                },
            }
        })
        setFormError('')
    }

    const handleBlur = (key) => {
        const { getError, error, required } = formContext[key]

        setFormContext((prevState) => {
            return {
                ...prevState,
                [key]: {
                    getError,
                    error,
                    isDirty: true,
                    required,
                },
            }
        })
    }

    const getFormError = () => {
        const postValidationFormContext = { ...formContext }

        let _formError = ''
        for (const key in formContext) {
            if (formContext[key]) {
                const value = formData[key]
                const { getError } = formContext[key]
                postValidationFormContext[key].isDirty = true
                const error = getError && getError(value, formData)
                postValidationFormContext[key].error = error
                if (error) {
                    _formError = 'Favor de revisar los errores.'
                }
            }
        }
        setFormContext(postValidationFormContext)
        setFormError(_formError)
        return _formError
    }

    return {
        formData,
        formContext,
        formError,
        handleChange,
        handleBlur,
        getFormError,
        setFormError,
    }
}

export { useForm }
