import { FC, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { ReceivablesAgingKpis } from './ReceivablesAgingKpis'
import { DashboardRevenueChart } from './DashboardRevenueChart'
import { ReceivablesAgingChart } from '../ReceivablesAgingChart'
import { PeriodicKpis } from './PeriodicKpis'
import { OptionButton, SelectButtons } from 'ds/components'
import { OrganizationSelect } from 'lib/common/OrganizationSelect'

export const AccountsReceivablesDashboard: FC = () => {
    const [currency, setCurrency] = useLocalStorage('dashboard_currency', 'MXN')

    const [organizationId, setOrganizationId] = useState<number | null>(null)

    return (
        <>
            <div>
                <div className="stacked-md inline inline-md">
                    <SelectButtons value={currency} onChange={setCurrency}>
                        <OptionButton value="MXN">MXN</OptionButton>
                        <OptionButton value="USD">USD</OptionButton>
                    </SelectButtons>
                </div>
                <OrganizationSelect
                    className="stacked-sm inline"
                    organizationId={organizationId}
                    onChange={setOrganizationId}
                />
            </div>

            <ReceivablesAgingKpis currency={currency} organization_id={organizationId} />

            <div className="row">
                <div className="col-xl-6">
                    <DashboardRevenueChart
                        currency={currency}
                        organization_id={organizationId}
                    />
                </div>
                <div className="col-xl-6">
                    <ReceivablesAgingChart
                        currency={currency}
                        organization_id={organizationId}
                    />
                </div>
            </div>

            <PeriodicKpis currency={currency} organization_id={organizationId} />
        </>
    )
}
