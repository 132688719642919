import { Card } from 'ds/components/Card'
import { Kpi } from 'ds/components/Kpi'
import { colors } from 'lib/utils/colors'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { getPercentagePaidDisplay } from './getPercentagePaidDisplay'
import { PieChart } from 'lib/common/PieChart'
import { useQuery } from '@tanstack/react-query'
import { reportService } from 'lib/services/reportService'
import { Spinner } from 'ds/components/Spinner'
import { ErrorBanner } from 'lib/common/ErrorBanner'
import { getAmountRemaining } from './getAmountRemaining'
import { InfoIcon } from 'ds/components'

type Props = {
    periodLabel: string
    currency: string | undefined
    dateParam: string
    start: Date | null
    end: Date | null
    organization_id: number | null
}

export const PeriodicSummary = ({
    periodLabel,
    currency,
    dateParam,
    start,
    end,
    organization_id,
}: Props) => {
    const { isLoading, data, error } = useQuery({
        queryKey: ['invoice', 'totals', currency, dateParam, organization_id, start, end],

        queryFn: () => {
            return reportService.getInvoiceTotals({
                organization_id,
                currency,
                dateParam,
                start,
                end,
            })
        },
    })

    if (isLoading) return <Spinner />
    if (error) return <ErrorBanner>Ha ocurrido un error: {error.message}</ErrorBanner>
    if (data.error) return <ErrorBanner>Ha ocurrido un error: {data.error}</ErrorBanner>

    const { invoiceTotals } = data
    const amountInvoiced = invoiceTotals.amount || 0
    const amountPaid = invoiceTotals.amountPaid || 0
    const amountRemaining = getAmountRemaining({
        amountInvoiced,
        amountPaid,
    })

    return (
        <Card>
            <div>
                <h3 className="inline inline-xs">Cobranza, {periodLabel}</h3>
                <InfoIcon inline>
                    De las ventas que se han facturado en el periodo, cuanto ya se cobro y
                    cuanto esta pendiente.
                </InfoIcon>
            </div>
            <div className="row">
                <div className="col-6">
                    <Kpi label="Ventas">{formatCurrency(amountInvoiced, 0)}</Kpi>
                </div>
                <div className="col-6 right">
                    <Kpi label="Cobrado">
                        {getPercentagePaidDisplay({
                            amountInvoiced,
                            amountPaid,
                        })}
                    </Kpi>
                </div>
            </div>
            <PieChart
                data={[
                    {
                        name: 'Por Cobrar del periodo',
                        value: Number(amountRemaining),
                        color: colors['primary-500'],
                    },
                    {
                        name: 'Cobrado del periodo',
                        value: Number(amountPaid),
                        color: colors['success-300'],
                    },
                ]}
            />
        </Card>
    )
}
