const colors = {
    // Primary Color
    'primary-500': '#2f81f7',

    // Danger Colors
    'danger-100': '#fde6e6',
    'danger-200': '#fabcbc',
    'danger-300': '#f79191',
    'danger-400': '#f56969',
    'danger-500': '#f85149', // Base
    'danger-600': '#e0433d',
    'danger-700': '#c83734',
    'danger-800': '#a92b2c',
    'danger-900': '#8a1f23',

    // Success Colors
    'success-100': '#f0f8f1',
    'success-200': '#bbe3c8',
    'success-300': '#2eae46',
    'success-400': '#28a143',
    'success-500': '#238636', // Base
    'success-600': '#1d6d2c',
    'success-700': '#165523',
    'success-800': '#103c19',
    'success-900': '#0a260f',

    // blue: '#2f81f7',
    red: '#f85149', // #dc3545
    // bluelight: '#3d8bfd', // #0f69ff, #006FEF
    // greenlight: '#33BB89', // #198754 in sass
    // red: '#eb0f29',

    // Yellow Colors
    'yellow-100': '#fff3cd',
    'yellow-200': '#ffe69c',
    'yellow-300': '#ffda6a',
    'yellow-400': '#ffcd39',
    'yellow-500': '#ffc107', // Bootstrap yellow
    'yellow-600': '#e0a800',
    'yellow-700': '#c69500',
    'yellow-800': '#9c6f00',
    'yellow-900': '#735100',

    // Neutral Colors
    'neutral-0': '#fff',
    'neutral-000': '#fafafa',
    'neutral-100': '#f8f9fa',
    'neutral-200': '#e9ecef',
    'neutral-300': '#dee2e6',
    'neutral-500': '#adb5bd',
    'neutral-600': '#6c757d',
    'neutral-700': '#495057',
    'neutral-400': '#ced4da',
    'neutral-800': '#343a40',
    'neutral-900': '#212325',
    'neutral-999': '#111111',
}

export { colors }
