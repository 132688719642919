import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = '/reports'

export const reportService = {
    getPaymentTotals: async (queryParams) =>
        makeApiRequest({ url: `${url}/payment-totals`, queryParams }),
    getReceivablesAging: async (queryParams) =>
        makeApiRequest({ url: `${url}/receivables-aging`, queryParams }),
    getInvoiceTotals: async (queryParams) =>
        makeApiRequest({ url: `${url}/invoice-totals`, queryParams }),
    getInvoiceTotalsByMonth: async (queryParams) =>
        makeApiRequest({ url: `${url}/invoice-totals-by-month`, queryParams }),
    getRevenueByMonth: async (queryParams) =>
        makeApiRequest({ url: `${url}/revenue-by-month`, queryParams }),
    getCustomerReport: async (body) =>
        makeApiRequest({ url: `${url}/customer`, method: 'POST', body }),
    getProductReport: async (body) =>
        makeApiRequest({ url: `${url}/product`, method: 'POST', body }),
    getBankTransactionReport: async (queryParams) =>
        makeApiRequest({ url: `${url}/bank-transaction`, queryParams }),
    getDaysSalesOutstandingReport: async (queryParams) =>
        makeApiRequest({ url: `${url}/days-sales-outstanding`, queryParams }),
    getReceivablesAgingByCustomer: async (queryParams) =>
        makeApiRequest({
            url: `${url}/receivables-aging/customer`,
            queryParams,
        }),
    getReceivablesByCustomerAndProduct: async (queryParams) =>
        makeApiRequest({
            url: `${url}/receivables-by-customer-product`,
            queryParams,
        }),
    getReceivablesByProduct: async (queryParams) =>
        makeApiRequest({ url: `${url}/receivables-by-product`, queryParams }),
    getOutstandingReport: async (queryParams) =>
        makeApiRequest({
            url: `${url}/invoice-outstanding-by-date`,
            queryParams,
        }),
    getEmailEventsByCustomer: async (queryParams) =>
        makeApiRequest({
            url: `${url}/email-events-by-customer`,
            queryParams,
        }),

    // Superadmin reports
    getAccountStats: async () => makeApiRequest({ url: `${url}/account-stats` }),
    getActiveStats: async () => makeApiRequest({ url: `${url}/active-stats` }),
    getRevenueStats: async () => makeApiRequest({ url: `${url}/revenue-stats` }),
}
