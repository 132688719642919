import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = '/organizations'

export const organizationService = {
    getOrganizations: async (queryParams) => makeApiRequest({ url, queryParams }),
    getOrganization: async ({ organization_id }) =>
        makeApiRequest({ url: `${url}/${organization_id}` }),
    create: async (body) => makeApiRequest({ url, method: 'POST', body }),
    putOrganization: async (body) =>
        makeApiRequest({
            url: `${url}/${body.organization_id}`,
            method: 'PUT',
            body,
        }),
    putOrganizationCsd: async (body) =>
        makeApiRequest({
            url: `${url}/${body.organization_id}/csd`,
            method: 'PUT',
            body,
        }),
    putOrganizationSequences: async (body) =>
        makeApiRequest({
            url: `${url}/${body.organization_id}/sequences`,
            method: 'PUT',
            body,
        }),
    putOrganizationCfdiSync: async (body) =>
        makeApiRequest({
            url: `${url}/${body.organization_id}/cfdi-sync`,
            method: 'PUT',
            body,
        }),
    archive: async ({ organization_id }) =>
        makeApiRequest({ url: `${url}/${organization_id}`, method: 'DELETE' }),
    getInvoicePreviewUrl: ({ organization_id }) =>
        makeApiRequest({ url: `${url}/${organization_id}/preview-invoice` }),
}
